import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client/core"
import userService from "@/services/userService";
import environment from "@/environment";
import {setContext} from '@apollo/client/link/context';
import {split} from '@apollo/client/core'
import {getMainDefinition} from '@apollo/client/utilities'
import {createApolloProvider} from '@vue/apollo-option'
import {createApp, h} from 'vue'
import App from '@/App.vue';
import {createClient} from "graphql-ws";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";

const httpLink = new HttpLink({
    uri: environment.HASURA_URL
})

function createNewWsLink() {
    return new GraphQLWsLink(
        createClient({
            url: environment.HASURA_WS,
            connectionParams: async () => {
                const token = await userService?.getToken();
                return {
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            }
        })
    );
}


let wsLink = createNewWsLink();

// authMiddleware
const authMiddLink = setContext(async (req, {headers}) => {

    const token = await userService?.getToken();
    return {
        headers: {
            ...headers,
            'authorization': 'Bearer ' + token
        }
    }
})

interface Definintion {
    kind: string;
    operation?: string;
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({query}) => {
        const {kind, operation}: Definintion = getMainDefinition(query)
        return kind === 'OperationDefinition' &&
            operation === 'subscription'
    },
    wsLink,
    authMiddLink.concat(httpLink),
)


export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

export const resetWsOptions = async () => {
    wsLink.client.terminate();
    wsLink = createNewWsLink();
    apolloClient.setLink(wsLink);
}

const app = createApp({
    render: () => h(App),
})

app.use(apolloProvider)

