import {RouteRecordRaw} from "vue-router";
import {Roles} from "@/commons/roles";
import userService from "@/services/userService";

const routes: RouteRecordRaw[] = [
    {
        path: '/login',
        component: () => import('@/layouts/LoginLayout.vue'),
        children: [
            {
                path: '/login', component: () => import('@/pages/login/LoginPage.vue'),
                name: 'login',
                props: (route) => {
                    return {
                        mode: route.query.mode,
                        oobCode: route.query.oobCode
                    }
                }
            },
            {
                path: '/register', component: () => import('@/pages/login/RegisterPage.vue'),
                name: 'register',
            },
            {
                path: '/waitlist', component: () => import('@/pages/login/WaitlistPage.vue'),
                name: 'waitlist'
            },
            {
                path: '/terms', component: () => import('@/pages/terms/TermsPage.vue'),
                name: 'terms'
            }
        ],
        beforeEnter: (to, from, next) => {
            const isLogged = userService.isLogged()
            if (isLogged)  {
                next({name: 'enterprises'})
            }else {
                next()
            }
        }
    },
    {
        path: '/',
        redirect: '/enterprises',
        component: () => import('@/layouts/TabsLayout.vue'),
        meta: { requiredRoles: [Roles.ROLE_SELLER] },
        children: [
            {
                path: 'enterprises', component: () => import('@/pages/enterprises/EnterprisesPage.vue'),
                name: 'enterprises'
            },
            {
                path: 'enterprise/:id', component: () => import('@/pages/enterprises/products-enterprise/ProductsByEnterprisePage.vue'),
                name: 'enterpriseSingle',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }

            },
            {
                path: 'orders', component: () => import('@/pages/orders/OrdersPage.vue'),
                name: 'orders'
            },
            {
                path: 'order/:id', component: () => import('@/pages/orders/DetailOrderPage.vue'),
                name: 'orderDetail',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'suborder/:id', component: () => import('@/pages/orders/SubOrderPage.vue'),
                name: 'subOrderDetail',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'shippings', component: () => import('@/pages/shippings/ShippingsPage.vue'),
                name: 'shippings'
            },
            {
                path: 'product/:id', component: () => import('@/pages/product/ProductPage.vue'),
                name: 'product',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'catalogs', component: () => import('@/pages/catalogs/CatalogsPage.vue'),
                name: 'catalogs'

            },
            {
                path: 'catalog/:id', component: () => import('@/pages/catalogs/products-catalog/ProductsByCatalogPage.vue'),
                name: 'catalogSingle',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }

            },
            {
                path: 'clients', component: () => import('@/pages/clients/ClientsPage.vue'),
                name: 'clients'
            },
            {
                path: 'profile', component: () => import('@/pages/profile/ProfilePage.vue'),
                name: 'profile'
            },
            {
                path: 'cart', component: () => import('@/pages/cart/CartPage.vue'),
                name: 'cart'
            },
            {
                path: 'campus', component: () => import('@/pages/campus/CampusPage.vue'),
                name: 'campus'
            },
            {
                path: 'benefits', component: () => import('@/pages/benefits/BenefitsPage.vue'),
                name: 'benefits'
            },
            {
                path: 'benefit/:id', component: () => import('@/pages/benefits/benefit-detail/BenefitDetailPage.vue'),
                name: 'benefit',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            }
        ]
    },
    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        redirect: '/'
    }
]

export default routes;
