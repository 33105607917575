<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts" setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { provide } from 'vue'
import {ApolloClients, provideApolloClient} from '@vue/apollo-composable'
import {apolloClient} from '@/extensions/apollo/apollo-client'

provide(ApolloClients, apolloClient)
provideApolloClient(apolloClient)

</script>
