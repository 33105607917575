import {createRouter, createWebHistory} from '@ionic/vue-router';

import routes from './routes'
import userService from "@/services/userService";


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {

    userService.loadUserFromStorage().then(() => {
        const meta = to.meta;
        // If no restriction information, next;
        if (!meta) {
            next();
        }

        const requiredRoles = meta.requiredRoles as string[];
        // If no required roles, next
        if (!requiredRoles) {
            next();
        }

        // Check roles
        if (userService.checkRolesOr(requiredRoles)) {
            next();
        } else {
            // Redirect to dashboard
            // noGrants();
            next({name: 'login'});
        }
    }).catch((err) => {
        console.log(err)
        next({name: 'login'});
    })

})
export default router
