import jwtDecode from 'jwt-decode';
import {authApi} from '@/boot/firebaseAuthApi';
import {ref} from 'vue'
import StorageService from "@/services/storageService";


export interface User {
    name: string,
    email: string,
    roles?: string[],
    phone?: string,
    userId: string,
    token: string,
    defaultRole: string
}

class UserService {

    private actualUser = ref<User>(
        {defaultRole: '', email: '', name: '', token: '', userId: '0'});

    // Main method to verify user, call this to open login modal when user is not logged
    // checkUserLogin() {
    //     if (this.isLogged())
    //         return;
    // }

    async getUser(): Promise<User | null> {
        await this.loadUserFromStorage();
        return this.actualUser.value;
    }

    async getToken(): Promise<string> {
        let tokenRet: string | null = '';

        await StorageService.getItem('AUTH').then((token) => {
            tokenRet = token;
        })

        if (tokenRet == null) {
            await this.loadOpenUser().then(() => {
                tokenRet = this.actualUser.value.token;
            }, (error) => {
                console.log(error);
            })
        }

        return tokenRet;
    }

    async logout(): Promise<boolean> {
        await StorageService.clear().then(() => {
            this.actualUser.value = {
                defaultRole: '', email: '', name: '', token: '', userId: '0'
            };
        });
        return true;
    }

    isLogged(): boolean {
        console.log(this.actualUser.value.userId)
        if (this.actualUser.value.userId === '0')
            return false;

        return this.actualUser.value.userId != null;
    }

    checkRolesOr(rolesToCheck: string[]): boolean {
        if (!rolesToCheck) return true;
        if (!this.actualUser) return false;

        const actualRoles = this.actualUser.value.roles;
        for (const entry of rolesToCheck)
            if (actualRoles?.includes(entry))
                return true;

        return false;
    }

    public processToken(token: string | undefined, typeProvider: string): Promise<string> {

        return new Promise<string>((resolve, reject) => {
            void authApi.verifyToken(typeProvider, 'MARKETPLACE', undefined, token).then((token2) => {
                if (token2 == null || token2.data == null || token2.data.token == null) {
                    reject(false);
                    return;
                }
                this.parseTokenAndLoadUser(token2.data.token)
                resolve('ok');
            }, (error) => {
                reject(error)
            })
        })
    }

    // Carga un usuario open
    private loadOpenUser(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.processToken(undefined, 'EMAIL').then(() => {
                resolve(true);
            }, (error) => {
                reject(error)
            })
        })
    }

    // Parsea el token y lo carga en el servicio y en el local storage
    private parseTokenAndLoadUser(token: string) {
        const decodeToken = jwtDecode<CustomAppToken>(token ? token : '');
        const name = decodeToken.name;
        const phone = decodeToken.phone;
        const email = decodeToken.email;
        const roles = decodeToken['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
        const userId = decodeToken['https://hasura.io/jwt/claims']['x-hasura-user-id'];
        const defaultRole = decodeToken['https://hasura.io/jwt/claims']['x-hasura-default-role'];

        this.actualUser.value = {
            roles: roles,
            name: name,
            email: email,
            phone: phone,
            userId: userId,
            token: token,
            defaultRole: defaultRole
        };
        this.saveTokenToStorage(token);
    }

    /**** STORAGE FUNCTIONS *****/
    public async loadUserFromStorage() {
        await StorageService.getItem('AUTH').then((token) => {
            if (token != null)
                this.parseTokenAndLoadUser(token);
        } )
    }

    private saveTokenToStorage(token: string) {
        StorageService.setItem('AUTH', token).then();
    }

}

interface hasuraToken {
    'x-hasura-default-role': string,
    'x-hasura-user-id': string,
    'x-hasura-allowed-roles': string[]
}

interface CustomAppToken {
    name: string,
    email: string,
    phone?: string,
    'https://hasura.io/jwt/claims': hasuraToken
}

export default new UserService();
