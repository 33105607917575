

class Environment {

    public HASURA_URL: string = (process.env.HASURA_URL) ?
        (process.env.HASURA_URL) : 'https://gql.revendo.com.ar/v1/graphql';

    public HASURA_WS: string = (process.env.HASURA_WS) ?
        (process.env.HASURA_WS) : 'wss://gql.revendo.com.ar/v1/graphql';

    public AUTH_URL: string = (process.env.AUTH_URL) ?
        (process.env.AUTH_URL) : 'https://auth.revendo.com.ar';

    public IDENTITY_PROVIDER_API_KEY: string = (process.env.IDENTITY_PROVIDER_API_KEY) ?
        (process.env.IDENTITY_PROVIDER_API_KEY) : 'AIzaSyDfyCzqPGhB2xw3E5t5UudVDmf2l4r5M08';

    public IDENTITY_PROVIDER_AUTH_DOMAIN: string = (process.env.IDENTITY_PROVIDER_AUTH_DOMAIN) ?
        (process.env.IDENTITY_PROVIDER_AUTH_DOMAIN) : 'floube-2d559.firebaseapp.com';

    public STORAGE_URL: string = (process.env.STORAGE_URL) ?
        (process.env.STORAGE_URL) : 'https://storage.revendo.com.ar';

    public CATALOG_URL: string = (process.env.CATALOG_URL) ?
        (process.env.CATALOG_URL) : 'https://catalogo.revendo.com.ar'
}

export default new Environment();
